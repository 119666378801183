<template>
    <div class="favorite">
        <div class="leftBox">
            <div class="tab_box">
                <el-tabs v-model="ShortTab" type="card" @tab-click="handleClick">
                    <el-tab-pane label="涨粉榜" name="first">
                        <authorRank v-if="this.ShortTab == 'first'" :videoId=this.videoList.id>
                        </authorRank>
                    </el-tab-pane>
<!--                    <el-tab-pane label="新人榜" name="second">-->
<!--                        <authorRank v-if="this.ShortTab == 'second'" :videoId=this.videoList.id>-->
<!--                        </authorRank>-->
<!--                    </el-tab-pane>-->
<!--                    <el-tab-pane label="热度榜" name="third">-->
<!--                        <authorRank v-if="this.ShortTab == 'third'" :videoId=this.videoList.id>-->
<!--                        </authorRank>-->
<!--                    </el-tab-pane>-->
<!--                    <el-tab-pane label="粉丝榜" name="fourth">-->
<!--                        <authorRank v-if="this.ShortTab == 'fourth'" :videoId=this.videoList.id>-->
<!--                        </authorRank>-->
<!--                    </el-tab-pane>-->
<!--                    <el-tab-pane label="掉粉榜" name="fifth">-->
<!--                        <authorRank v-if="this.ShortTab == 'fifth'" :videoId=this.videoList.id>-->
<!--                        </authorRank>-->
<!--                    </el-tab-pane>-->
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import authorRank from "../../../components/mediumModule/AuthorRank"
    export default {
        name: "HotBroadcaster",
        components: {
            authorRank
        },
        data() {
            return {
                ShortTab: this.$route.query.ShortTab || 'first',
                videoList: {},//视频详情
            }
        },
        created() {

        },
        methods: {
            handleClick(tab, event) {
            },
        }
    }
</script>

<style scoped lang="scss">
    .favorite {
        height: 100%;
        display: flex;

        .leftBox {
            width: 100%;

            .head_box {
                width: 100%;
                height: 160px;
                background-color: #FFFFFF;

                .head_title {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .left_title {
                        display: flex;

                        .pic_box {
                            padding: 20px;
                        }

                        .data_box {
                            padding: 10px 0;

                            .like {
                                display: flex;
                                font-size: 14px;
                                font-weight: 400;

                                span {
                                    display: flex;
                                    justify-content: start;
                                    align-items: center;
                                    margin-right: 15px;
                                }
                            }

                            .hotWord {
                                /*.el-tag {*/
                                /*    background: #f4f4f4;*/
                                /*    border-radius: 12px;*/
                                /*    border: 1px solid #f4f4f4;*/
                                /*    height: 24px;*/
                                /*    line-height: 24px;*/
                                /*    font-size: 12px;*/
                                /*    font-family: Microsoft YaHei;*/
                                /*    font-weight: 400;*/
                                /*    color: #333;*/
                                /*    padding: 0 15px;*/
                                /*    margin-left: 5px;*/
                                /*}*/
                            }
                        }
                    }

                    .right_button {
                        padding: 20px;
                    }
                }
            }

            .tab_box {
                display: flex;
                width: 100%;

                ::v-deep .el-tabs {
                    height: 100%;
                    width: 100%;
                    box-shadow: none;
                    border: none;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    background: transparent;
                }

                ::v-deep .el-tabs__header {
                    border-bottom: none;
                    margin: 0;
                }

                ::v-deep .el-tabs__content {
                    height: 1%;
                    flex: 1;
                    background: #fff;
                    padding-top: 20px;
                    margin-bottom: 20px;
                }

                ::v-deep .el-tabs__header .is-active {
                    background: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    color: #0824ce;
                    border-radius: 6px 6px 0 0;
                }

                ::v-deep .el-tabs__item {
                    border: none;
                    height: 52px;
                    line-height: 52px;
                }

                ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                    border: none;
                }

                ::v-deep .el-tab-pane {
                    height: 100%;
                }
            }
        }

        .rightBox {
            width: 20%;
            height: 100%;
            background-color: #FFFFFF;
            margin: 10px 20px 0 10px;

            .head_title {
                text-align: center;
                width: 100%;
                height: 30px;
                font-size: 16px;
                padding-top: 20px;
            }

            .listBox {

                .box1 {
                    display: flex;
                    margin-left: 10px;
                    padding-bottom: 10px;

                    .num {
                        border-radius: 50%;
                        background: rgb(0, 0, 255);
                        width: 16px;
                        height: 16px;
                        color: rgb(255, 255, 255);
                        text-align: center;
                        margin-left: 10px;
                        margin-top: 2px;
                    }

                    .title {
                        margin-left: 10px;
                        width: 270px;
                    }
                }
            }
        }
    }

</style>